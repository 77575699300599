html {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  min-width: 320px;
  scroll-behavior: smooth;
}

body {
  color: #fff;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

textarea,
input,
select {
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  font-size: 14px;
  color: #000;
  font-family: 'Montserrat', sans-serif;
}

input[type='checkbox'] {
  -webkit-appearance: checkbox;
}

button {
  background: none;
  outline: none;
  border: none;
  font-family: inherit;
}

#root {
  display: flex;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 40px;
  font-weight: 200;
}

p {
  font-weight: 300;
  line-height: 33px;
}

.content {
  color: #000;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border-width: 0;
}
